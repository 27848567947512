var site = site || {};
var rb = rb || {};
rb.language = rb.language || {};
site.productView = site.productView || {};
site.shadeFilter = site.shadeFilter || {};
site.productView.mpp = {
  init: function(productsData) {
    var $mppProductGrids = $('.mpp__product-grid');
    var isSingleColumn = $('.js-single-column').length ? 1 : 0;
    var $grid = $();
    if ($('.product-heading-mpp').length !== 0) {
      $mppProductGrids = $('.column-main');
    }

    if (!$mppProductGrids.length) {
      return;
    }

    $mppProductGrids.each(function(gridCount, grid) {
      $grid = $(grid);
      var $mppProducts = $('.product-brief', $grid);
      // get row count
      var mppRows = _.groupBy($mppProducts, function(element, index) {
        if (!!isSingleColumn) {
          return index;
        }
        return Math.floor(index / 2); //2 in a row
      });
      mppRows = _.toArray(mppRows);
      var $quickshopContainer = '';
      //even out row height
      setTimeout(function() { // timeout to let fonts render - @font-face FOUT
        site.productView.mpp.rowHeight(mppRows);
        $('.mpp__product-grid').animate({opacity: 1}, 100);
      }, 120);

      // fix row index to add grid index
      for (var i = 0; i < mppRows.length; i++) {
        var $mppRow = $(mppRows[i]);
        //console.log($mppRow);
        var rowIndex = gridCount + '-' + i;
        $mppRow.each(function(index, product) {
          $(product).attr('data-row-index', rowIndex);
        });
        // $mppRow.data('row-index', gridCount + '-' + i);
        if (!!isSingleColumn) {
          var $quickshopContainer = '<li class="product-brief__quickshop-container js-quickshop-container" data-row-index="' + rowIndex + '"></li>';
          $mppRow.eq(0).after($quickshopContainer);
        }

        $mppRow.eq(1).addClass('product-brief--second');
        $quickshopContainer = '<li class="product-brief__quickshop-container js-quickshop-container" data-row-index="' + rowIndex + '"></li>';
        $mppRow.eq(1).after($quickshopContainer);
        if (i === parseInt(mppRows.length - 1)) {
          if (!!isSingleColumn) {
            return;
          }
          // last row
          // check if only one in row
          if ($mppRow.length === 1) {
            $mppRow.eq(0).after($quickshopContainer);
            $mppRow.addClass('left');
          }
        }
      }

      // update js-quickshop text for each mpp product
      $('.js-quickshop-show').each(function () {
        var self = $(this);
        var outOfStockCount = 0;
        var outOfStockDefaultSku = false;
        var noStockStatusNumbers = [2, 3, 5, 7];
        var productId = self.data('product-id');
        var productData = productsData.find((p) => p.PRODUCT_ID === productId);
        var productSkus = productData.skus;
        var defaultSku = productData.defaultSku;

        // iterate over the skus for reviewing its inventory status
        if (productSkus) {
          productSkus.forEach(function (skuData) {
            var inventoryStatus = skuData.INVENTORY_STATUS;

            if (inventoryStatus && noStockStatusNumbers.includes(inventoryStatus)) {
              outOfStockCount++;
            }
          });
        }
        if (defaultSku && noStockStatusNumbers.includes(defaultSku.INVENTORY_STATUS)) {
          outOfStockDefaultSku = true;
        }
        if (!productSkus || outOfStockCount === productSkus.length || outOfStockDefaultSku) {
          self.html(site.translations.product.sold_out);
        } else {
          self.html(site.translations.product.quick_view);
        }
      });

      //update row height on resize
      $(window).on('resize', _.debounce(function() {
        site.productView.mpp.rowHeight(mppRows);
      }, 250));

      // update row height after inv status is updated
      $('.js-inv-status-list').off('inventory_status_updated');
      $('.js-inv-status-list').on('inventory_status_updated', function() {
        var $parentContainer = $(this).parents('.product-brief');
        var rowToResize = _.find(mppRows, function(mppRow) {
          return _.contains(mppRow, $parentContainer[0]);
        });
        site.productView.mpp.rowHeight([rowToResize]);
      });
    });
    // initialize individual product views
    for (var i = 0; i < productsData.length; i++) {
      productsData[i] = site.shadeFilter.loadProductFilterData(productsData[i]);
      site.productView.mobileMppItem(productsData[i]);
      var $prodId = productsData[i].PRODUCT_ID;
      var $shopNowBtn = $('li[data-product-id="' + $prodId + '"] .js-quickshop-show, li[data-product-id="' + $prodId + '"] .js-add-to-cart');
      var skus = productsData[i].skus;
      var preOrderCount = 0;
      $(skus).each(function(i, product) {
        if (product.isPreOrder === 1) {
          preOrderCount += 1;
        }
      });
      if (preOrderCount === skus.length) {
        $shopNowBtn.text(site.translations.product.pre_order);
      }
    }
    $('.js-mpp_quickshop').on('click', function() {
      site.shadeFilter.init();
      site.shadeFilter.filter();
    });
  }, // end init

  rowHeight: function(mppRows) {
    _.each(mppRows, function(mppRow) {
      _.each(mppRow, function(productContainer) {
        $(productContainer).removeAttr('style');
      });
    });

    site.productView.miscFlagAlign(mppRows);

    //misc flag - extra padding
    $(mppRows).each(function(index, row) {
      var hasFlag = 0;
      var flagHeight = '19px';
      $(row).each(function(index, product) {
        if ($(product).find('.product-brief__misc-flag').length) {
          hasFlag++;
          flagHeight = $(product).find('.product-brief__misc-flag').outerHeight(true);
        }
      });
      $(row).each(function(index, product) {
        if (hasFlag && $(product).find('.product-brief__misc-flag').length === 0) {
          // check if the spaceer already exists before attempting to insert one
          if (!$(product).find('.product-brief__misc-flag-spacer')) {
            var $spacer = $('<div class="product-brief__misc-flag-spacer">');
            $spacer.height(flagHeight);
            $(product).find('.product-brief__header').before($spacer);
          }
        }
      });
    });

    for (var x in mppRows) {
      var mppRow = mppRows[x];
      var maxProductHeight = Math.max.apply(null, $(mppRow).map(function() {
        return $(this).outerHeight();
      }).get());
      if ($(mppRow).hasClass('single-column')) {
        return;
      }
      $(mppRow).outerHeight(maxProductHeight);
    }
  }
};

site.productView.miscFlagAlign = function(cols) {
  if (!cols.length) {
    return;
  }

  var $row, $flags, $spacers;
  for (var i = 0, j = cols.length; i < j; i++) {
    $row = $(cols[i]);

    $flags = $('.product-brief__misc-flag', $row);
    $spacers = $('.product-brief__misc-flag-spacer', $row);

    // Remove any inline css on flags and flag spacers
    if ($flags.length) {
      $flags.removeAttr('style');
    }
    if ($spacers.length) {
      $spacers.removeAttr('style');
    }

    // Go through all products and add spacers where appropriate
    $row.each(function(i, product) {
      var $product = $(product);
      var spacer;

      if ($product.find('.product-brief__misc-flag').length) {
        // Already has a flag. Do nothing.
      }
      else if ($product.find('.product-brief__misc-flag-spacer').length) {
        // Alfready has a spacer. Move along.
      }
      else {
        $spacer = $('<h5 class="product-brief__misc-flag-spacer" />');
        $product.find('.product-brief__header').before($spacer);
      }
    });

    // Get the maximum height from all flags in the grid
    var maxFlagHeight = Math.max.apply(null, $flags.map(function() {
      return $(this).outerHeight(true);
    }).get());

    // Make sure we have all spacers, even the ones we just added
    $spacers = $('.product-brief__misc-flag-spacer', $row);

    //  Set all flags and spacers to the max height
    $flags.add($spacers).height(maxFlagHeight);
  }
}

site.productView.mobileMppRowHeights = function() {
  var $mppProducts = $('.product-brief');
  var mppRows = _.groupBy($mppProducts, function(element, index) {
    return Math.floor(index / 2); //2 in a row
  });
  mppRows = _.toArray(mppRows);
  $mppProducts.removeAttr('style');
  site.productView.mpp.rowHeight(mppRows);
};

// duplicate problem
site.productView.mobileMppItem = function(productData) {
  var that = {
    productData: productData
  };
  var p = that.productData;
  var $prodContainer = $(".product-brief[data-product-id='" + p.PRODUCT_ID + "']");
  if ($prodContainer.length === 0) {
    return;
  }
  $prodContainer.each(function(index, product) {
    var $product = $(product);
    var pos = $product.hasClass('product-brief--second') ? 1 : 0;
    var rowIndex = $product.attr('data-row-index');
    var $qsContainer = $(".js-quickshop-container[data-row-index='" + rowIndex + "']");
    var qs = site.productView.inlineQuickshopMobile({
      productData: p,
      position: pos,
      $productContainer: $product,
      $quickshopContainer: $qsContainer,
    });
    var $showButton = $(".js-quickshop-show[data-product-id='" + p.PRODUCT_ID + "']", $product);
    var $hideButton = $(".js-quickshop-hide[data-product-id='" + p.PRODUCT_ID + "']", $product);
    $showButton.off('click');
    $showButton.on('click', function(evt) {
      evt.preventDefault();
      qs.$quickshopContainer.attr('data-productid', qs.$productContainer.data('product-id'));
      qs.launch();
      $('.js-quickshop-hide').addClass('hidden'); // hide all hide buttons
      $('.js-quickshop-show').removeClass('hidden'); // show all show buttons
      $hideButton.removeClass('hidden');
      $showButton.addClass('hidden');
    });

    $hideButton.off('click');
    $hideButton.on('click', function(evt) {
      evt.preventDefault();
      qs.close();
      $hideButton.addClass('hidden');
      $showButton.removeClass('hidden');
    });

    var $prodImg = $(".product-brief__image[data-product-id='" + p.PRODUCT_ID + "']", $product);
    $prodImg.off('sku:select');
    $prodImg.on('sku:select', function(e, skuData) {
      var skuImg = Array.isArray(skuData.MEDIUM_IMAGE) ? skuData.MEDIUM_IMAGE[0] : skuData.MEDIUM_IMAGE;
      $(this).css('background-image', 'url(' + skuImg + ')');
      e.stopPropagation;
    });
  });
  return that;
};

site.productView.mobileMppRebuild = function(productData) {
  var $mppProducts = $('.product-brief');
  $mppProducts.removeClass('product-brief--second');
  // remove quickshop
  var $quickshop = $('.js-quickshop-container');
  $quickshop.remove();
  site.productView.mpp.init(productData);
};
